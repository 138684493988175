import { regions, rpcEffectiveByDate } from "../constant/constants";
import { getProvinceCode } from "../page/PageUtils";

const getDataLoadMode = () => {
    return "";
};

const getInitialDataForStartQuote = () => {
    return {zipCode: '', transactionData: {}};
};

const getDefaultedDataForPrimaryDriverBasicInfo = () => {
    return {basicInfo: {firstName: 'John', middleInitial:'M', lastName : 'Doe', militaryStatus: 'active', gender: 'M', maritalStatus: 'S', dateOfBirth: "01-20-1980"},
        address: { streetAddress: '100 Fake Street', unitNumber : 'A', poBox : '210', city : 'Pasadena', province : 'OR', zipCode : '97206'},
        email : 'jdoe@wawanesa.com', emailConfirm : 'jdoe@wawanesa.com', preferredDistribution: 'email', phoneNumber : '888-888-8888', extension: '',relationshipToPNI: 'insured', transactionData: {}};
};

const getInitialDataForPrimaryDriverBasicInfo = (region) => {
    if(getDataLoadMode() === 'DEFAULT'){
        return getDefaultedDataForPrimaryDriverBasicInfo()
    }

    return {basicInfo: {firstName: '', middleInitial:'', lastName : '', militaryStatus: 'no', gender: '', maritalStatus: '', dateOfBirth: ''},
        address: { streetAddress: '', unitNumber : '', poBox : '', city : '', province : getProvinceCode(region), zipCode : ''},
        email : '', emailConfirm : '', preferredDistribution: 'email', phoneNumber : '', extension : '', relationshipToPNI:'insured', transactionData: {}};
};

const getDefaultedDataForPrimaryDriverAdditionalInfo = () => {
    return {
        relationshipToPNI: 'insured',
        hasCurrentInsurance: 'Yes',
        currentInsuranceCompany: '21st Century',
        timeWithcurrentCarrier: '4 years',
        additionalInfo : {
            ageCategory: '',
            driverTrainingCourseTaken: '',
            matureDriverTrainingCourseTaken3Yrs: '',
            matureDriverTrainingCourseTaken2Yrs: '',
            stateLicensed: 'OR',
            licensedAge: '18',
            militaryStatus: '',
            isAcquiringStateLicense: '',
            hasHeldDriverLicenseFor18Months: '',
            hasBeenSuspended: false,
            isLicenseActive: true,
            wasSuspensionForMoreThan60Days: false,
            reasonForSuspension: 'Suspension was for medical reasons',
            hasBeenConvicted: false,
            mostRecentConviction: 'none',
            hasBeenConvictedOtherViolations: false,
            otherviolations: 'fail_stop_sch_bus',
            selectedViolations: ['none'],
            hasThereBeenAnyAccidents: false,
            wasTheDriverAtFault: false,
            wereDamagesOver1000: false,
            assignmentType: '',
        },
        ownOrRentPrimaryResidence: 'ownHome',
        hearAboutWawanesa: '',
        heardAboutWawanesaFromOther: '',
        insuranceScoreConsent: false
    };
};

const getInitialDataForPrimaryDriverAdditionalInfo = (region) => {
    if(getDataLoadMode() === 'DEFAULT'){
        return getDefaultedDataForPrimaryDriverAdditionalInfo()
    }
    return {
        relationshipToPNI: 'insured',
        hasCurrentInsurance: '',
        currentInsuranceCompany: '',
        timeWithcurrentCarrier: '',
        additionalInfo : {
            ageCategory: '',
            driverTrainingCourseTaken: '',
            matureDriverTrainingCourseTaken3Yrs: '',
            matureDriverTrainingCourseTaken2Yrs: '',
            hasHeldDriversLicense: '',
            stateLicensed: getProvinceCode(region),
            licensedAge: '',
            militaryStatus: '',
            isAcquiringStateLicense: '',
            hasHeldDriverLicenseFor18Months: '',
            hasBeenSuspended: '',
            isLicenseActive: '',
            wasSuspensionForMoreThan60Days: '',
            reasonForSuspension: '',
            hasBeenConvicted: '',
            mostRecentConviction: 'none',
            hasBeenConvictedOtherViolations: '',
            otherviolations: '',
            selectedViolations: ['none'],
            hasThereBeenAnyAccidents: '',
            wasTheDriverAtFault: '',
            wasAnyoneKilledOrInjured: '',
            wereDamagesOver1000: '',
            assignmentType: '',
            hasBeenConvictedWithinLastTenYears: '',
            hasThereBeenAnyAtFaultAccidents: '',
            selectedAccidents: ['none']          
        },
        ownOrRentPrimaryResidence: '',
        hearAboutWawanesa: '',
        heardAboutWawanesaFromOther: '',
        insuranceScoreConsent: false,
        affinityGroup: 'no'
    };
};

const getDefaultDataForAdditionalDriver = () => {
    return {
        basicInfo: {firstName: 'Jane', middleInitial:'L', lastName : 'Doe', militaryStatus: 'retired', gender: 'F', maritalStatus: 'M', dateOfBirth: "01-20-1985"},
        preferredDistribution: "mail",
        relationshipToPNI: "spouse",
        additionalInfo : {
            ageCategory: '',
            driverTrainingCourseTaken: '',
            matureDriverTrainingCourseTaken3Yrs: '',
            matureDriverTrainingCourseTaken2Yrs: '',
            stateLicensed: 'OR',
            licensedAge: '19',
            hasBeenSuspended: false,
            isLicenseActive: true,
            wasSuspensionForMoreThan60Days: false,
            reasonForSuspension: 'Suspension was for medical reasons',
            hasBeenConvicted: false,
            mostRecentConviction: 'none',
            hasBeenConvictedOtherViolations: false,
            otherviolations: 'fail_stop_sch_bus',
            selectedViolations: ['none'],
            hasThereBeenAnyAccidents: false,
            wasTheDriverAtFault: false,
            wereDamagesOver1000: false,
            hasHeldDriverLicenseFor18Months: '',
        },
    };
};

const getInitialDataForAdditionalDriver = (region) => {
    if(getDataLoadMode() === 'DEFAULT'){
        return getDefaultDataForAdditionalDriver()
    }
    return {
        basicInfo: {firstName: '', middleInitial:'', lastName : '', militaryStatus: 'no', gender: '', maritalStatus: '', dateOfBirth: ''},
        preferredDistribution: "mail",
        relationshipToPNI: '',
        additionalInfo : {
            ageCategory: '',
            driverTrainingCourseTaken: '',
            matureDriverTrainingCourseTaken3Yrs: '',
            matureDriverTrainingCourseTaken2Yrs: '',
            hasHeldDriversLicense: '',
            stateLicensed:  getProvinceCode(region),
            licensedAge: '',
            hasBeenSuspended: '',
            isLicenseActive: '',
            wasSuspensionForMoreThan60Days: '',
            reasonForSuspension: '',
            hasBeenConvicted: '',
            mostRecentConviction: '',
            hasBeenConvictedOtherViolations: '',
            otherviolations: '',
            selectedViolations: ['none'],
            hasThereBeenAnyAccidents: '',
            wasTheDriverAtFault: '',
            wasAnyoneKilledOrInjured: '',
            wereDamagesOver1000: '',
            hasHeldDriverLicenseFor18Months: '',
            assignmentType: '',
            hasBeenConvictedWithinLastTenYears: '',
            hasThereBeenAnyAtFaultAccidents: '',
            selectedAccidents: ['none']
        }
    };
};

const getInitialDataForCoverage = (region) => {
   return region === regions.CALIFORNIA ? getCAInitialDataForCoverage() : getORInitialDataForCoverage();
};

const getORInitialDataForCoverage = () => {
    let result = {
        PA_BICov: {coverageValue: "50_100", coverageSelected: true}, PA_PDCov: {coverageValue: "50,000", coverageSelected: true}, PA_UNIMSBICov: {coverageValue: "50_100", coverageSelected: true}, PA_PIPCov: {coverageValue: "15,000", coverageSelected: true},
        PA_ComprehensiveCov: {coverageValue: "500", coverageSelected: true}, PA_CollisionCov: {coverageValue: "500", coverageSelected: true}, PA_RoadAssistCov: {coverageValue: "", coverageSelected: false}, PA_UNIMSPDCov: {coverageValue: "", coverageSelected: false}, 
        PA_VMRPCov: {coverageValue: "", coverageSelected: false}, PA_RentalExpCov: {coverageValue: "30_900", coverageSelected: true}
    };

    if (rpcEffectiveByDate) {
        result.PA_RentalExpCov = {coverageValue: "35_1050", coverageSelected: true};
    }

    return result;
}

const getCAInitialDataForCoverage = () => {
    return {
        PA_BICov: {coverageValue: "100_300", coverageSelected: true}, PA_PDCov: {coverageValue: "50,000", coverageSelected: true},
        PA_ComprehensiveCov: {coverageValue: "1,000", coverageSelected: true}, PA_CollisionCov: {coverageValue: "1,000", coverageSelected: true}, PA_RoadAssistCov: {coverageValue: "", coverageSelected: false},
        PA_UNIMSPDCov: {coverageValue: "", coverageSelected: false}, PA_UNIMSBICov: {coverageValue: "30_60", coverageSelected: true}, PA_VMRPCov: {coverageValue: "", coverageSelected: false},
        PA_RentalExpCov: {coverageValue: "40_1200", coverageSelected: true}, PA_MedPayCov: {coverageValue: "5,000", coverageSelected: true},
        PA_UMCovWaiver: {coverageValue: "", coverageSelected: false}, PA_UMPropertyCovWaiver: {coverageValue: "", coverageSelected: false},
        PA_UNIMSCollCov: {coverageValue: "", coverageSelected: true}, PA_UMCollisionCovWaiver: {coverageValue: "", coverageSelected: false}
    }; 
};

const getDefaultDataForVehicle = (region) => {
    return {
        primaryUse: "commuting",
        totalAnnualMileage: region === regions.CALIFORNIA ? 12000 : 9500,
        oneWayMileage: region === regions.CALIFORNIA ? 10 : 5,
        numberOfDaysAWeek: 5,
        pleasureMilesPerYear: 7000,
        showAnnualMileageCalculator: false,
        theftRecoverySystemPresent: "true",
        coOwnOrLeaseVehicle: "true",
        isVehicleRegisteredToANI: '',
        garagedAtPrimaryAddress: "true",
        address: { streetAddress: '111 Fake Street', unitNumber : 'A', poBox : '211', city : 'Pasadena', province : getProvinceCode(region), zipCode : '97206'},
        haveShellOrCamper: 'Neither',
        coverages: getInitialDataForCoverage(region)
    };
};

const getInitialDataForVehicle = (region) => {
    if(getDataLoadMode() === 'DEFAULT'){
        return getDefaultDataForVehicle(region)
    }
    return {
        primaryUse: "commuting",
        totalAnnualMileage: region === regions.CALIFORNIA ? 12000 : 9500,
        oneWayMileage: region === regions.CALIFORNIA ? 10 : 5,
        numberOfDaysAWeek: 5,
        pleasureMilesPerYear: 7000,
        showAnnualMileageCalculator: false,
        theftRecoverySystemPresent: "",
        coOwnOrLeaseVehicle: true,
        isVehicleRegisteredToANI: '',
        garagedAtPrimaryAddress: true,
        address: { streetAddress: '', unitNumber : '', poBox : '', city : '', province : getProvinceCode(region), zipCode : ''},
        haveShellOrCamper: 'Neither',
        coverages: getInitialDataForCoverage(region)
    };
};

export {getInitialDataForStartQuote, getInitialDataForPrimaryDriverBasicInfo, getInitialDataForPrimaryDriverAdditionalInfo, getInitialDataForAdditionalDriver, getInitialDataForVehicle, getInitialDataForCoverage}